const buttonSubmitId = 'button-553a9e46';
const blockVerify = 'input-7f7f9451-container';
const buttonSendCodeId = 'send-code';
const buttonVerify = 'verify-code';
const verifyPhoneDiv = 'verify-phone-div';
const successSendId = 'success-send';
const errorSendId = 'error-send';
const successVerifyId = 'success-verify';
const errorVerifyId = 'error-verify';
const phoneInputSelector = 'input[data-label="phone_number"]';
const codeInputSelector = 'input[data-variable="verify-code"]';

window.addEventListener('heyflow-init', async (event) => {
    const inputToken = document.querySelector('input[data-variable="token"]');
    if (inputToken) {
        inputToken.type = 'hidden';
    }

    const token = getTokenFromMeta() || await fetchToken();

    if (token) {
        inputToken.value = token;
        createAuthorizationMetaTag(token);
    } else {
        console.error('Error fetching token');
    }

    document.getElementById(buttonSendCodeId).addEventListener('click', async (event) => {
        const buttonSend = event.target;
        buttonSend.disabled = true;

        hideElement(successSendId);
        hideElement(errorSendId);

        const phone = document.querySelector(phoneInputSelector).value;
        const data = {
            phone,
            token,
            url: window.location.href
        };

        try {
            const response = await fetch('https://sms.promos-exclusives.info/api/phone/send/code', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            if (responseData.send) {
                showElement(successSendId);
                showElement(blockVerify);
                showElement(verifyPhoneDiv);
            } else {
                showElement(errorSendId);
            }
        } catch (error) {
            console.error('Error sending code:', error);
            showElement(errorSendId);
        } finally {
            buttonSend.disabled = false; 
        }
    });

    document.getElementById(buttonVerify).addEventListener('click', async (event) => {
        const buttonVerify = event.target;
        buttonVerify.disabled = true;

        hideElement(successVerifyId);
        hideElement(errorVerifyId);

        const code = document.querySelector(codeInputSelector).value;
        const data = {
            code,
            token,
            url: window.location.href
        };

        try {
            const response = await fetch('https://sms.promos-exclusives.info/api/phone/verify/code', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            console.log(responseData);
            if (responseData.success) {
                const buttonSubmit = document.getElementById(buttonSubmitId);
                buttonSubmit.disabled = false;
                showElement(successVerifyId);
                buttonSubmit.classList.remove('button-disabled');
                document.querySelector(phoneInputSelector).readOnly = true;
                document.querySelector(codeInputSelector).readOnly = true;
                document.getElementById(buttonSendCodeId).disabled = true;
            } else {
                const buttonSubmit = document.getElementById(buttonSubmitId);
                buttonSubmit.classList.remove('button-disabled');
                showElement(errorVerifyId);
            }
        } catch (error) {
            console.error('Error verifying code:', error);
            showElement(errorVerifyId);
        } finally {
         
        }
    });

    init();
});

function getTokenFromMeta() {
    const metaTag = document.querySelector('meta[name="authorization-token-ld"]');
    return metaTag ? metaTag.getAttribute('content') : null;
}

function fetchToken() {
    const currentPageUrl = encodeURIComponent(window.location.href);
    const apiUrl = `https://sms.promos-exclusives.info/api/token?url=${currentPageUrl}`;
    return fetch(apiUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
            return response.json();
        })
        .then(data => data.token)
        .catch(error => {
            console.error('Error fetching token:', error);
            return null;
        });
}

function createAuthorizationMetaTag(token) {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'authorization-token-ld');
    metaTag.setAttribute('content', token);
    document.head.appendChild(metaTag);
}

function init() {
    disableButton(buttonSubmitId);
    hideElement(verifyPhoneDiv);
    hideElement(blockVerify);
}

function disableButton(buttonId) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.disabled = true;
        button.classList.add('button-disabled');
    }
}

function hideElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.style.display = 'none';
    }
}

function showElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
        element.style.display = 'block';
    }
}